import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Soundcloud = makeShortcode("Soundcloud");
const Video = makeShortcode("Video");
const ImageGrid = makeShortcode("ImageGrid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Tōsh`}</h1>
    <p>{`Tōsh is the live electronic project of San Francisco-based musician and entrepreneur Ashutosh Desai. He performs alongside instrumentalists bringing jazz-style improvisation and collaboration to modern electronic music. He has studied under Sebastian Mullaert (`}<a parentName="p" {...{
        "href": "https://www.circleof.live"
      }}>{`Circle of Live`}</a>{`), Hannes Bieger, and Billy Caso, with influence from artists and producers such as Darkside, Bonobo, George Fitzgerald, Hania Rani, Jamie xx, and Danger Mouse.`}</p>
    <h2>{`Live Performances`}</h2>
    <p>{`Tōsh primarily performs live-electronic club sets alongside guitarist `}<a parentName="p" {...{
        "href": "https://www.instagram.com/nicholaspaul26/"
      }}>{`Nicholas Paul`}</a>{`. Nicholas spent decades writing, recording, and touring with progressive rock acts in Chicago.`}</p>
    <Soundcloud playlistId="1872362930" token="QsvZVrnCHC0" height="300" mdxType="Soundcloud" />
    <h2>{`Concert Film`}</h2>
    <p>{`Italian director Rodolfo Lissia (`}<a parentName="p" {...{
        "href": "https://onira.one/films/"
      }}>{`Onira films`}</a>{`) filmed their debut performance at 1015 Folsom, opening for Nicola Cruz at a sold-out, near 1,000 attendee show.`}</p>
    <Video videoId="dM5x2gIIGhM" alt="1015 Folsom" gutterBottom mdxType="Video" />
    <br />
    <p>{`The club set is composed of original samples produced or recorded in Tōsh's Mission District studio, layered with improvisational analog instruments - guitar, synthesizer, and drum machine. The concept for the performance inherits from the improvisational paradigms of Jazz and Indian Classical paired with the sound design and functionality of modern house and techno. Unlike most live acts, the set does not use a backing track and the instrumental layers are not pre-planned. Instead, stems, samples, and instruments are mixed live to create fluidity in arrangement and create more responsiveness to the audience and the moment.`}</p>
    <p>{`Tōsh and Nicholas Paul have performed live at Robot Heart, 1015 Folsom, Fly Ranch, Public Works, Supra (CDMX), Reactor Venao (Panama), F8 SF, Maxa Camp, and Play Alchemist. Photos from sunrise on the Robot Heart bus at Burning Man 2022, shot by photographer and director `}<a parentName="p" {...{
        "href": "http://www.christianlamb.com/about"
      }}>{`Christian Lamb`}</a>{`.`}</p>
    <ImageGrid src1="https://res.cloudinary.com/studiocollective/image/upload/v1665459486/Studio%20Collective/Robot%20Heart%20Pics/157F81BF-8EB8-49AE-9383-0CA70A714904_zcbutn.jpg" src2="https://res.cloudinary.com/studiocollective/image/upload/v1665459485/Studio%20Collective/Robot%20Heart%20Pics/7A5F02E1-CE3E-437A-9C57-B06795FB04B7_ccnbpy.jpg" src3="https://res.cloudinary.com/studiocollective/image/upload/v1665459486/Studio%20Collective/Robot%20Heart%20Pics/A7B175B5-20C2-4FFA-A36A-0984FA11D0B6_jyhvdr.jpg" src4="https://res.cloudinary.com/studiocollective/image/upload/v1665459486/Studio%20Collective/Robot%20Heart%20Pics/BCFE6135-EADE-4BC6-8784-F06E1C1D4671_vfywy3.jpg" src5="https://res.cloudinary.com/studiocollective/image/upload/v1665459486/Studio%20Collective/Robot%20Heart%20Pics/44F4ABB4-932D-47F1-806B-3B24107C499A_2_qruypo.jpg" src6="https://res.cloudinary.com/studiocollective/image/upload/v1665459486/Studio%20Collective/Robot%20Heart%20Pics/C2225B86-6E7D-49D4-ACDC-6F9F1A76464F_ivjtqx.jpg" mdxType="ImageGrid" />
    <h2>{`Introspect - Live from Black Rock City`}</h2>
    <p><a parentName="p" {...{
        "href": "https://studiocollective.xyz/introspect"
      }}>{`Introspect`}</a>{` is an experimental live album recorded at Burning Man by San Francisco-based producer Tōsh & guitarist Nicholas Paul.`}</p>
    <p>{`The concert recordings were captured at first light of Burning Man 02022 on the Robot Heart bus, followed by performances in the PlayAlchemist pyramid and the Maxa camp lounge.`}</p>
    <p>{`The album was released on vinyl in fall 2023. In the spirit of decommodification, the vinyl is available as a gift - ask us for a copy :)`}</p>
    <Soundcloud playlistId="1585250455" token="0ZlmLjGJ8t0" height="450" mdxType="Soundcloud" />
    <ImageGrid src1="https://res.cloudinary.com/studiocollective/image/upload/v1687814110/Studio%20Collective/Live%20Album/10mb%20squares/Back_vpy3os.png" src2="https://res.cloudinary.com/studiocollective/image/upload/v1687814110/Studio%20Collective/Live%20Album/10mb%20squares/Cover_aqklkg.png" src4="https://res.cloudinary.com/studiocollective/image/upload/v1687814115/Studio%20Collective/Live%20Album/10mb%20squares/Inside_L_gsdbg8.png" src3="https://res.cloudinary.com/studiocollective/image/upload/v1687814109/Studio%20Collective/Live%20Album/10mb%20squares/Innersleeve_A_ylxrcr.png" src6="https://res.cloudinary.com/studiocollective/image/upload/v1687814116/Studio%20Collective/Live%20Album/10mb%20squares/Innersleeve_B_bvhnk9.png" src5="https://res.cloudinary.com/studiocollective/image/upload/v1687814109/Studio%20Collective/Live%20Album/10mb%20squares/Inside_R_qncmwp.png" mdxType="ImageGrid" />
    <h2>{`DJ Sets`}</h2>
    <p>{`In addition to performing live, Tōsh has held DJ residencies at F8 (a San Francisco nightclub), Hotel California (a burning man camp), and creative co-ops in San Francisco. Tōsh has DJ'd at San Francisco venues such as Public Works, Great Northern, 1015 Folsom, with burning man camps such as Titanic's End, Airpusher, Disco Space Shuttle, Ooligan Alley, and internationally in France, Mexico City, and New Zealand.`}</p>
    <Soundcloud playlistId="1264074025" token="oEC4HbviuP8" height="450" mdxType="Soundcloud" />
    <h2>{`Biography`}</h2>
    <p><strong parentName="p">{`Ashutosh Desai`}</strong>{` (Tōsh) grew up between Hong Kong, India, and California with an influence of Japanese culture through his schooling. His grandfather served as music critic for the Times of India reviewing performances by musicians like Ravi Shankar and Amjad Ali Khan, and his mother practiced 18 years of Bharatnatyam dance. Despite this, Ashutosh didn't pick up music as a creative pursuit in childhood, instead focusing on mathematics and computer science.`}</p>
    <p>{`He dropped out of college at age 19 and founded a new university based in San Francisco focused on creating pathways for youth from underrepresented backgrounds into the tech industry. The school operated for 9 years producing thousands of alumni and hundreds of bachelor's degree graduates before closing mid-2021.`}</p>
    <p>{`He began attending Burning Man at age 20, intruiged by the intersection of Maker culture, civic design, technology, and dance, and began DJing a few years later. He established himself as a staple in the SF and Burning Man club scene through his taste in dance-forward yet emotionally expressive music. He was the resident DJ and curator for the camp `}<a parentName="p" {...{
        "href": "https://www.instagram.com/hotelcaliforniabm/"
      }}>{`Hotel California`}</a>{`, and served as a production manager and photographer for the `}<a parentName="p" {...{
        "href": "https://ashu.xyz/residency/"
      }}>{`Robot Heart Residency`}</a>{`.`}</p>
    <p>{`Ashutosh visited Detroit for Movement festival where the Circle of Live improvizational techno performance inspired him to study music and production to construct live electronic sets. Arriving to music in his late 20's forced Ashutosh to design his own non-traditional education. His expertise in technology, education, and galvanizing teams gave him a good foundation on which to rapidly acclimate to modern music, which is increasingly technical, mathematical, and entrepreneurial. He studied music theory and production through online courses and through mentorship with internationally touring live acts Sebastian Mullaert, Hannes Bieger, and Billy Caso. He continues to deepen his music knowledge through studying more traditional forms including both Western and Indian Classical, and Jazz.`}</p>
    <br />
    <p><strong parentName="p">{`Nicholas Paul Brysiewicz`}</strong>{` grew up outside Chicago, discovering the guitar at age 10 and playing his first club show at age 15. He spent decades in Chicago writing, recording, and performing with progressive rock acts like The Fireship, Sun Cellar, and Alaya. He then moved to San Francisco—where he now helps run The Long Now Foundation, a nonprofit focused on the next 10,000 years (an organization founded by ambient music pioneer Brian Eno).`}</p>
    <p>{`With a background in systems engineering and continental philosophy, Nicholas is interested in abstract patterns and unrepeatable improvisations. His playing is polymetrical, which creates unexpected anchors for movement (i.e. makes you feel like a better dancer). He quite literally plays to a different drummer. `}</p>
    <p>{`Nicholas enters each performance with fresh ears, having never heard the arrangement before stepping on stage. You’re hearing the music with him. And he is responding to it with you.`}</p>
    <br />
    <h2>{`Additional Projects`}</h2>
    <h4>{`1x1 Live Set Performances`}</h4>
    <p>{`In addition to performances with Nick, Tōsh is exploring a 1x1 performance format with other instrumentalists. The following performance took place with `}<a parentName="p" {...{
        "href": "https://www.instagram.com/rozihtmusic/"
      }}>{`Roziht Eve`}</a>{`, a pianist/cellist/composer from the Conservatory of Music in San Francisco. Visuals were designed and performed live by `}<a parentName="p" {...{
        "href": "https://www.instagram.com/wearesubset/"
      }}>{`Subset`}</a>{`.`}</p>
    <Video videoId="SaqanYIuukw" alt="Tosh x Roziht" mdxType="Video" />
    <h4>{`Piano x Electronic Explorations`}</h4>
    <p>{`Inspired by pianists like Hania Rani, Ólafur Arnalds, Philip Glass, Bugge Wesseltoft, and Nils Frahm, Tōsh is studying the intersection of piano and electronic music with the support of Roziht. The goal is to explore a different dimension of electronic music, rooted in a fundamentally different audience experience - one that is silent and seated.`}</p>
    <Video videoId="CRPxuyT7luc" alt="Piano x Electronic" preview mdxType="Video" />
    {
      /* <Video videoId="JDuyag_XmeI" alt="Piano" preview/> */
    }
    <h4>{`Audio Visual Explorations`}</h4>
    <p>{`Inspired by AV projects by Max Cooper, The Blaze, and Sebastian Mullaert, Tōsh is exploring work with filmmakers and visual artists involved with The Studio Collective to produce concert films, music videos, and backing visuals for shows.`}</p>
    <p>{`The team is exploring a number of different approaches from cinematography-based visuals to generative visuals. The following is a work-in-progress example of a concept filmed in New York City.`}</p>
    <Video videoId="tlsDIOyZsig" alt="City Walks" preview mdxType="Video" />
    <h4>{`California Hills Live Set`}</h4>
    <p>{`Tōsh's live set concept was largely developed through the pandemic during a quiet period for the music world. He took this opportunity to film an iteration of the live set to begin sharing his original music with his community. The set was filmed by Bosnian cinematographer Igor Terzic in Jan 2021 on a ranch in the California hills, and colorgraded by Italian editor `}<a parentName="p" {...{
        "href": "https://www.ludovicamantovan.com"
      }}>{`Ludovica Mantovan`}</a>{`. The video will be released in 2023.`}</p>
    <Video videoId="TNxtMCVu5HU" alt="Live Set Video" wide mdxType="Video" />
    <h4>{`Burning Man Project's Fly Ranch`}</h4>
    <p>{`Tōsh and Nicholas Paul were invited to perform the first show with amplified sound at Burning Man Project's Fly Ranch property in May 2021.`}</p>
    <p>{`Fly Ranch is Burning Man Org's experimental property aiming to prototype what longer-term civic design can look like inspired by on-playa experiences. The performance demonstrated what the future of Burning Man music can look like, with an emphasis on live performance, collaboration, and musicianship rather than DJing. `}</p>
    <p>{`The performance used Hotel California's solar-powered sound infrastructure - which Tōsh helped build - to illustrate how Burning Man and similar events can become carbon neutral.`}</p>
    <Video videoId="mQ0JTDc-4sE" alt="Fly Ranch" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      